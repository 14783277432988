import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'

export const SLayout = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${size(165)};
`

export const SMainLayout = styled.main`
  position: relative;
  padding: 0 ${size(10)} ${size(10)};
  min-height: 100vh;
`

export const SSidebarLayout = styled.main`
  overflow: auto;
  position: relative;
`

export const SSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${size(80)};
  padding: ${size(8)};
  min-height: 100vh;
`

export const SWizardLayout = styled.div`
  margin: 0 auto;
  max-width: ${size(155)};
  padding-bottom: ${size(20)};
  width: 100%;
`

export const SWizardFullLayout = styled(SWizardLayout)`
  padding: 0 ${size(11)} ${size(25)};
  max-width: 100%;
`
